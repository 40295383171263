<template>
  <PageNotFound />
</template>

<script>
import PageNotFound from '@/views/general/under-maintenance.vue'

export default {
  components: { PageNotFound },
}
</script>